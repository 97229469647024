<template>
  <div class="company-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="real-estate/building/page"
      @selection-change="handleSelectionChange"
    >
      <el-button
        v-if="$auth('楼宇新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l('common.add','新增')}}
      </el-button>
      <el-button
        v-if="$auth('楼宇合并')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.mergePage.open(selectList)">
        {{$l("common.add", "合并")}}
      </el-button>
      <el-button
        v-if="$auth('楼宇导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        action="real-estate/building/import"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
        </div>
      </template>


      <!--      <el-table-column align="center" type="index" width="50"></el-table-column>-->
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
      <el-table-column prop="name" label="楼宇名称" align="center"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
      <el-table-column prop="aboveGroundFloorCount" label="地上楼层计数" align="center"></el-table-column>
      <el-table-column prop="underGroundFloorCount" label="地下楼层计数" align="center"></el-table-column>
      <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
      <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('楼宇编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <!--          <el-button type="primary" @click="$refs.floorEdit.open(scope.row)">{{$l('common.edit','楼层对照表')}}</el-button>-->
          <el-button v-if="$auth('楼宇删除')" type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
    <floor-edit ref="floorEdit" @save-success="getList(-1)"></floor-edit>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/building.xlsx"
        action="real-estate/building/import"
        name="楼宇导入"
        download-name="楼宇模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  import MergePage from "./Merge";
  import FloorEdit from "@/views/elevatorUsingBuilding/FloorEdit";
  import {downloadFiles} from "@/util";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {EditPage,MergePage,FloorEdit},
    data() {
      return {
        loading:false,
        visible:false,
        filter: {
          name: "",
          creditCode: "",
          companyId: loginUtil.getCompanyCode(),
        },
        selectList:[],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("real-estate/building", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      async downFile() {
        this.loading=true;
        downloadFiles("excelTemplate/building.xlsx","楼宇模板.xlsx",()=> {
          this.loading=false;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
