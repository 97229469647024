<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','编辑楼宇'):$l('company.add','新增楼宇')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <el-form-item label="楼宇名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="楼盘名称" prop="realEstateId">
        <vm-select-input :value="model.realEstateName" @select="$refs.customerSelect.open()"></vm-select-input>
      </el-form-item>


      <el-form-item label="地上楼层计数" prop="aboveGroundFloorCount">
        <el-input-number
          v-model="model.aboveGroundFloorCount"
          controls-position="right"
          :min="0"
          :precision="0"
          style="width: 100%"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="地下楼层计数" prop="underGroundFloorCount">
        <el-input-number
          v-model="model.underGroundFloorCount"
          controls-position="right"
          :min="-2"
          :max="0"
          :precision="0"
          style="width: 100%"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="经度" prop="longitude">
        <el-input v-model="model.longitude" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="维度" prop="latitude">
        <el-input v-model="model.latitude" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
  </el-dialog>
</template>
<script>

  import CustomerSelect from "@/views/elevatorUsingRealEstate/RealEstateSelect";

  export default {
    components: {CustomerSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        floorList:[],
        model: {
          id: 0,
          name: "",
          shortName: "",
          type: "",
          creditCode: "",
          contactName: "",
          contactPhone: "",
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`real-estate/building/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            // this.$http.get(`real-estate/floor/${this.model.id}`).then(res => {
            //   this.contentLoading = false;
            //   this.floorList=[];
            //   // this.model = data;
            //   if (res instanceof Array && res.length>0&&res.length===(this.model.aboveGroundFloorCount-this.model.underGroundFloorCount)) {
            //     this.floorList= res;
            //   }else {
            //     const count = this.model.aboveGroundFloorCount-this.model.underGroundFloorCount;
            //     for (let i = 0; i < count; i++) {
            //       if (i+this.model.underGroundFloorCount<0) {
            //         this.floorList.push({
            //           buildingId:this.model.id,
            //           name:i+1,
            //           displayName:i+this.model.underGroundFloorCount,
            //         });
            //       }else {
            //         this.floorList.push({
            //           buildingId:this.model.id,
            //           name:i+1,
            //           displayName:i+this.model.underGroundFloorCount+1,
            //         });
            //       }
            //     }
            //     console.log(98,this.floorList);
            //   }
            // });
          });
          
        }
      },
      selectCustomer(row) {
        this.$set(this.model,"realEstateId",row.id);
        this.$set(this.model,"realEstateName",row.name);
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("real-estate/building", this.model)
              .then((data) => {
                this.submitLoading = false;
                this.dialogVisible = false;
                if (data) {
                  this.$emit("save-success");
                  this.$message.success("保存成功");
                }else {
                  this.$message.error("保存失败");
                }
                // this.$http
                //   .save("real-estate/floor", this.floorList)
                //   .then(() => {
                //     this.submitLoading = false;
                //     this.dialogVisible = false;
                //
                //   })
                //   .catch(() => {
                //     this.submitLoading = false;
                //   });
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      updateFloors() {
        console.log(169,this.model.aboveGroundFloorCount-this.model.underGroundFloorCount);
        const count = this.model.aboveGroundFloorCount-this.model.underGroundFloorCount;
        if (count!==this.floorList.length) {
          this.floorList=[];
          for (let i = 0; i < count; i++) {
            if (i+this.model.underGroundFloorCount<0) {
              this.floorList.push({
                buildingId:this.model.id,
                name:i+1,
                displayName:i+this.model.underGroundFloorCount,
              });
            }else {
              this.floorList.push({
                buildingId:this.model.id,
                name:i+1,
                displayName:i+this.model.underGroundFloorCount+1,
              });
            }
          }
        }

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>