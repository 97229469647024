<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','编辑楼宇'):$l('company.add','新增楼宇')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <el-table
        :data="floorList"
        label-position="left"
        class="demo-table-expand">
        <el-table-column prop="name" label="物理楼层" align="center" width="170">
        </el-table-column>

        <el-table-column prop="displayName" label="标识楼层" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.displayName"
              controls-position="right"
              :precision="0"
              style="width: 100%"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog> 
</template>
<script>


  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        floorList:[],
        row:{},
        model: {
          id: 0,
          name: "",
          shortName: "",
          type: "",
          creditCode: "",
          contactName: "",
          contactPhone: "",
        },
      };
    },
    methods: {
      open(row) {
        this.model.id = row.id;
        console.log(row);
        this.row=row;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`real-estate/floor/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.floorList=[];
            // this.model = data;
            if (data instanceof Array && data.length>0&&data.length===(this.row.aboveGroundFloorCount-this.row.underGroundFloorCount)) {
              this.floorList= data;
            }else {
              console.log(82);
              const count = this.row.aboveGroundFloorCount-this.row.underGroundFloorCount;
              for (let i = 0; i < count; i++) {
                if (i+this.row.underGroundFloorCount<0) {
                  this.floorList.push({
                    buildingId:this.row.id,
                    name:i+1,
                    displayName:i+this.row.underGroundFloorCount,
                  });
                }else {
                  this.floorList.push({
                    buildingId:this.row.id,
                    name:i+1,
                    displayName:i+this.row.underGroundFloorCount+1,
                  });
                }
              }
              console.log(98,this.floorList);
            }
          });
        }
      },
      selectCustomer(row) {
        this.$set(this.model,"realEstateId",row.id);
        this.$set(this.model,"realEstateName",row.name);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("real-estate/floor", this.floorList)
              .then((data) => {
                this.submitLoading = false;
                this.dialogVisible = false;
                if (data) {
                  this.$emit("save-success");
                  this.$message.success("保存成功");
                }else {
                  this.$message.error("保存失败");
                }

              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>