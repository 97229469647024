<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('客户楼盘楼盘')" label="楼盘" name="first"><real-estate></real-estate></el-tab-pane>
    <el-tab-pane v-if="$auth('客户楼盘楼宇')" label="楼宇" name="second"><building></building></el-tab-pane>
  </el-tabs>
</template>

<script>
  import RealEstate from "@/views/elevatorUsingRealEstate/List";
  import Building from "@/views/elevatorUsingBuilding/List";
  export default {
    components:{RealEstate,Building},
  };
</script>

<style scoped>

</style>